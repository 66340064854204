<template>
  <div
    v-if="shouldDisplay"
    :id="uniqueId"
    class="small-section results-ad column is-12"
  />
</template>

<script>
import postscribe from 'postscribe';
import { mapState } from 'vuex';

export default {
  props: {
    index: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    uniqueId: `result-ad-${window.performance.now()}-${Math.random().toString(36).substring(7)}`,
    adEveryXRecords: 3,
  }),
  computed: {
    ...mapState({
      ads: (state) => state.advertisements.results_ads,
    }),
    shouldDisplay() {
      return !!(// if
        this.ads?.length // there are ads
          && this.index > 0 // the index is not 0
          && this.index % 3 === 0 // is congruent with 4
          && this.index / 3 <= this.ads?.length // and has the correct count
          // && this.ads[this.index / 3] !== undefined // and the ad is not undefined
      );
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.shouldDisplay) {
        postscribe(`#${this.uniqueId}`, this.ads[(this.index / 3) - 1]);

        document.querySelectorAll('.ais-InfiniteHits-item.column.is-4 .AdUnit > div > div').forEach((adUnit) => {
          const currentLi = adUnit.closest('.ais-InfiniteHits-item.column.is-4');
          const newLi = document.createElement('li');

          newLi.classList.add('ais-InfiniteHits-item', 'column', 'is-12', 'ad');
          newLi.appendChild(adUnit);
          currentLi.parentNode.insertBefore(newLi, currentLi);
        });
      }
    }, 1000);
  },
};
</script>
